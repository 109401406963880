<template>
  <div class="card-body">
    <div
      style="display:flex;justify-content:space-between;align-items:center;"
    ></div>
    <hr />
    <div class="row mb-md-2">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
    </div>

    <div class="table-responsive mb-0">
      <b-table
        :items="meunItemsTempArray"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        ref="selectableTable"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(id)="meunItemsTempArray">
          <input
            type="checkbox"
            :value="meunItemsTempArray.item.id"
            v-model="checkedNames"
          />
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>;

<script>
import Vue from "vue";
export default {
  components: {},
  props: {
    meunItems: {
      type: Array,
      required: true,
    },
    meunItemsTempArray: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      checkedNames: [],
      quantity: "",
      meunItemsIds: [],
      deliveryAddresstempArray: [],
      deliveryAddressArray: [],
      deliveryCost: "",
      minimumOrderAmount: "",
      isSelectAll: false,
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        { key: "name", label: "Menu Item Name", sortable: true },
        { key: "quantity", label: "Quantity", sortable: true },
        { key: "price", label: "Unit Price", sortable: true },
        { key: "total_price", label: "Total price", sortable: true },
        { key: "actions", sortable: true },
      ],
    };
  },

  mounted() {},

  computed: {
    rows() {
      return this.meunItemsTempArray.length;
    },
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    customLabel({ id, name }) {
      return `${id}—(${name})`;
    },

    onMenuItemRemove({ price }) {
      this.totalPrice = this.totalPrice - price;
    },

    onlyForCurrency($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.deliveryCost.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.deliveryCost != null &&
        this.deliveryCost.indexOf(".") > -1 &&
        this.deliveryCost.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },

    appendRow() {
      if (this.meunItemsIds.length == 0) {
        this.toasterMessageFalse("Meun Required");
        return false;
      }

      if (this.quantity == "") {
        this.toasterMessageFalse("Quantity is required");
        return false;
      }
      let data = {
        name: this.meunItemsIds.name,
        menu_id: this.meunItemsIds.id,
        price: this.meunItemsIds.price,
        quantity: this.quantity,
        total_price: this.meunItemsIds.price * this.quantity,
      };
      this.meunItemsTempArray.push(data);
      this.$emit(
        "totalPriceIncrement",
        this.meunItemsIds.price * this.quantity
      );
      this.meunItemsIds = [];
    },

    toasterMessageSuccess(message) {
      Vue.$toast.success(message, {
        position: "top-right",
        duration: 5000,
      });
    },
    toasterMessageFalse(message) {
      Vue.$toast.error(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    deleteData(object) {
      this.$emit("deleteData", object, "delete-menu-item");
    },
  },
};
</script>
