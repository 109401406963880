<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      ref="verfication-modal"
      hide-footer
      title="Discount Menu Item List"
      id="discount-menu-modal"
    >
      <div class="d-block">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="menuListArray"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              ref="selectableTable"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
            >
              <template v-slot:cell(id)="menuListArray">
                <input
                  type="checkbox"
                  :value="menuListArray.item.id"
                  v-model="checkedNames"
                />
              </template>

              <template v-slot:cell(actions)="menuListArray">
                <a
                  v-bind:class="'btn btn-danger btn-sm'"
                  @click="deleteData(menuListArray)"
                >
                  Delete
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="float: right">
        <button type="button" class="btn btn-secondary" @click="hideModal">
          Close
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    menuListArray: {
      type: Array,
    },
  },
  data() {
    return {
      checkedNames: [],
      quantity: "",
      meunItemsIds: [],
      deliveryAddresstempArray: [],
      deliveryAddressArray: [],
      deliveryCost: "",
      minimumOrderAmount: "",
      isSelectAll: false,
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        { key: "menu_item_name", label: "Item Name" },
        { key: "actual_price", label: "Actual Price" },
      ],
    };
  },

  mounted() {
    if (this.actionType == "delete-resturant-image") {
      this.modalBodyText = "Do You Want To Delete This Record?";
    }

    if (this.actionType == "delete-menu-item") {
      this.modalBodyText = "Do You Want To Delete This Record?";
    }

    if (this.actionType == "delete") {
      this.modalBodyText = "Do You Want To Delete This Record?";
    }

    if (this.actionType == "deactive") {
      this.modalBodyText = "Do You Want To Activate This Record?";
    }
    if (this.actionType == "delete-skip-image") {
      this.modalBodyText = "Do You Want To Delete This Image?";
    }
    if (this.actionType == "active") {
      this.modalBodyText = "Do You Want To Deactivate This Record?";
    }

    this.$refs["verfication-modal"].show();
    this.showModel = true;
  },

  computed: {
    rows() {
      return this.menuListArray.length;
    },
  },

  methods: {
    hideModal() {
      this.showModel = false;
      this.$refs["verfication-modal"].hide();
      this.$emit("closeModal", false);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    deleteRecord() {
      try {
        const data = { id: this.userObj.item.id };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/user/order/softDelete", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.$refs["verfication-modal"].hide();
              this.$emit("closeModal", false);
              this.$emit("deleteRow", this.userObj);
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    deleteRestaurantImage() {
      try {
        const data = { image_id: this.userObj.item.id };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/delete-restaurant-image", data, {
          // headers,
        }).then((response) => {
          if (response.data.code == 200) {
            this.$refs["verfication-modal"].hide();
            this.$emit("closeModal", false);
            this.$emit("deleteImageRecord", this.userObj);
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    deleteMeunItem() {
      this.$emit("closeModal", false);
      this.$emit("deleteMeunItem", this.userObj);
    },

    changeStatus() {
      try {
        const data = {
          id: this.userObj.item.id,
          status: this.userObj.item.status,
        };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/user/order/activeDeactive", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.postButtonLoader = true;
              this.$refs["verfication-modal"].hide();
              this.$emit("closeModal", false);
              this.$emit("changeStatus", response.data.data);
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    commonAction() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      if (this.actionType == "delete") {
        this.deleteRecord();
      }
      if (this.actionType == "delete-resturant-image") {
        this.deleteRestaurantImage();
      }
      if (this.actionType == "delete-menu-item") {
        this.deleteMeunItem();
      }
      if (this.actionType == "active" || this.actionType == "deactive") {
        this.changeStatus();
      }
    },
  },
};
</script>
