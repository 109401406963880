<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-5">Voucher</h4>
      <table class="table table-bordered">
        <thead class="thead">
          <tr>
            <th>Id</th>
            <th>Referral By</th>
            <th>Referral To</th>
            <th>Type</th>
            <th>Expire duration</th>
          </tr>
        </thead>
        <tbody class="allergies">
          <tr>
            <td>
              {{ this.voucher.voucher.unique_code }}
            </td>
            <td>
              {{
                this.voucher.voucher.type == "Referral code"
                  ? this.voucher.referralBy.username
                  : "-"
              }}
            </td>
            <td>
              {{
                this.voucher.voucher.type == "Referral code"
                  ? this.voucher.referralTo.username
                  : "-"
              }}
            </td>

            <td>
              {{ this.voucher.voucher.type }}
            </td>
            <td>
              {{ this.voucher.expire_duration }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    voucher: {},
  },
  data() {
    return {
      checkedNames: [],
    };
  },

  mounted() {},

  methods: {},
};
</script>
