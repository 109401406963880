<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center"
        style="height:100vh"
      >
        <img
          :src="'./images/loader.gif'"
          class="mr-2"
          style="width:100px;height:100px"
        />
      </div>
      <b-form v-if="!loading">
        <div class="card">
          <div></div>
          <div class="col-12 mt-2">
            <div></div>
            <div class="card-header cardHeader-bg" style="padding: 14px;">
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                :class="
                  changeClasss ? 'alert alert-success' : 'alert alert-danger'
                "
              >
                {{ alertText }}
              </div>
              <h4 class="card-title mb-0">{{ headerTitle }}</h4>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group
                id="input-group-2"
                label="Customer:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  type="text"
                  v-model="customerId"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Restaurant Name:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  type="text"
                  v-model="selected"
                ></b-form-input>
              </b-form-group>
              <br /><br />
            </div>
          </div>
        </div>
        <br />

        <div class="card">
          <template>
            <DetailMenuItems
              :meunItems="meunItems"
              @totalPriceIncrement="totalPriceIncrement"
              :meunItemsTempArray="meunItemsTempArray"
            />
          </template>

          <br />
        </div>

        <br />

        <div class="card">
          <template>
            <DiscountList
              @showDiscountedMenuItemModel="showDiscountedMenuItemModel"
              :discountList="discountList"
              :meunItemsTempArray="meunItemsTempArray"
            />
          </template>

          <br />
        </div>
        <br />
        <template v-if="this.voucher">
          <Voucher :voucher="voucher" />
        </template>
        <br />
        <br />

        <div class="card">
          <div></div>
          <div class="col-12 mt-2">
            <div></div>
          </div>
          <div class="card-body">
            <div>
              <div class="d-flex" v-if="showInputForDelivery">
                <b-form-group
                  style="width:33%"
                  readonly
                  class="mr-3"
                  id="input-group-2"
                  label="House No:"
                  label-for="input-2"
                >
                  <b-form-input
                    readonly
                    v-model="house_no"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  style="width:33%"
                  class="mr-3"
                  id="input-group-2"
                  label="Floor No:"
                  label-for="input-2"
                >
                  <b-form-input
                    readonly
                    v-model="street"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  style="width:33%"
                  id="input-group-2"
                  label="City:"
                  label-for="input-2"
                >
                  <b-form-input
                    readonly
                    v-model="city"
                    type="text"
                    placeholder="City"
                  ></b-form-input>
                </b-form-group>
                <br />
              </div>

              <div class="d-flex" v-if="showInputForDelivery">
                <b-form-group
                  style="width:50%"
                  class="mr-3"
                  id="input-group-2"
                  label="Street Name *"
                  label-for="input-2"
                >
                  <b-form-input
                    readonly
                    v-model="street"
                    type="text"
                    placeholder="Enter Street name"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  style="width:50%"
                  id="input-group-2"
                  label="Post Code:"
                  label-for="input-2"
                >
                  <b-form-input
                    readonly
                    v-model="postcode"
                    type="text"
                    placeholder="Post Code"
                  ></b-form-input>
                </b-form-group>
              </div>

              <b-form-group
                id="input-group-2"
                label="Amount paid:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  v-model="totalPrice"
                  type="number"
                  required
                  placeholder="Total Price"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                v-if="showInputForDelivery"
                id="input-group-2"
                label="Delivery Cost:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  v-model="deliveryCost"
                  type="text"
                  required
                  placeholder="Delivery Cost"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Order Status:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  type="text"
                  v-model="orderStatus"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                v-if="showDeliveryTimeInput"
                id="input-group-3"
                label="Delivery Time:"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="deliveryTime"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Type:">
                <b-form-radio-group id="radio-group-1" v-model="type">
                  <b-form-radio value="1">Delivery</b-form-radio>
                  <b-form-radio value="0">Pickup</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Voucher Discount"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  v-model="voucher_discount"
                  type="number"
                  required
                  placeholder="Total Price"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Payment Status:">
                <p>{{ paymentStatus }}</p>
              </b-form-group>

              <br /><br />
            </div>
          </div>
        </div>
        <br /><br />
        <template v-if="isShowDiscountMenuList">
          <DiscountMenuList
            :menuListArray="menuListArray"
            @closeModal="closeModal"
          />
        </template>

        <br /><br />
        <br />
      </b-form>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";

import DiscountMenuList from "./DiscountMenuList";
import Voucher from "./Voucher";
import DetailMenuItems from "@/view/pages/Orders/DetailMenuItems";
import DiscountList from "@/view/pages/Orders/DiscountList";

// import VueGoogleAutocomplete from "vue-google-autocomplete";
import axios from "axios";
export default {
  components: {
    DiscountMenuList,
    DiscountList,
    Voucher,
    DetailMenuItems,
  },
  data() {
    return {
      deliveryAddresses: [],
      meunItemsTempArray: [],
      menuListArray: [],
      paymentStatus: "",
      street_address: "",
      deliveryTime: null,
      deliveryAddress: null,
      minimunOrderAmout: null,
      showInputForDelivery: true,
      discountList: [],
      deliveryCost: null,
      customers: [],
      delivery_time: "",
      type: "1",
      customerId: null,
      headerTitle: "Order Detail",
      meunItems: [],
      voucher: {},
      meunItemsIds: [],
      totalPrice: 0.0,
      changeClasss: false,
      showDeliveryTimeInput: false,
      currentAddressname: "",
      email: "",
      name: "",
      size: null,
      id: "",
      selected: null,
      user: null,
      users: [],
      imagesShow: false,
      isShowDiscountMenuList: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      isShowModel: false,
      alertText: "",
      alert: false,
      showImagesViewTable: false,
      deliveryAddressView: false,
      price: "",
      address: "",
      postcode: "",
      orderdetail: "",
      house_no: "",
      city: "",
      street: "",
      orderId: null,
      loading: true,
      show: true,
      voucher_discount: 0.0,
      apiUrl: "/customer/orderPlace",
      orderStatus: null,
      orderStatuses: [
        { text: "Select Order Status", value: null },
        "accepted",
        "rejected",
        "dispatch",
        "pending",
      ],
    };
  },

  mounted() {
    this.loading = true;
    this.getRecord();
  },
  methods: {
    onSelect(option) {
      this.totalPrice = 0.0;
      this.meunItemsIds = [];
      this.meunItemsTempArray = [];
      this.deliveryAddress = null;
      this.meunItems = option.meunItems;
      this.deliveryAddresses = option.addresses;
    },

    showDiscountedMenuItemModel(disountedMenuItemArray) {
      this.menuListArray = disountedMenuItemArray;
      this.isShowDiscountMenuList = true;
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    customLabel({ id, name }) {
      return `${id}—(${name})`;
    },

    totalPriceIncrement(price) {
      let grandTotal = parseFloat(this.totalPrice) + parseFloat(price);
      this.totalPrice = parseFloat(grandTotal).toFixed(2);
    },

    customLabelForCustomer({ id, email }) {
      return `${id}—(${email})`;
    },
    customLabelDeliveryAddress({ id, location }) {
      return `${id}—(${location})`;
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    getRecord() {
      this.orderId = JSON.parse(localStorage.getItem("orderId"));
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        const data = {
          id: this.orderId,
        };
        axios
          .post(this.$path + "/user/orderDetail", data, {
            headers,
          })
          .then((response) => {
            this.orderdetail = response.data.data;
            this.voucher = this.orderdetail.voucher;
            this.orderId = this.orderdetail.id;
            if (this.orderdetail.payment_status == 0) {
              this.paymentStatus = "Pending";
            } else {
              this.paymentStatus = "Done";
            }
            this.id = this.orderdetail.id;
            this.meunItemsTempArray = this.orderdetail.customerOrdersDetail;
            this.selected = this.orderdetail.restaurant.name;
            this.customerId =
              this.orderdetail.customerDetail.username +
              " - " +
              this.orderdetail.customerDetail.email;
            this.street_address = this.orderdetail.street_address;
            this.meunItems = this.orderdetail.meunItem
              ? this.orderdetail.meunItem
              : [];
            this.discountList = this.orderdetail.discounteditems;
            this.voucher_discount = this.orderdetail.voucher_discount;
            this.totalPrice = this.orderdetail.totalprice;
            this.orderStatus = this.orderdetail.order_status;
            this.deliveryTime = this.orderdetail.delivery_time;
            if (this.orderStatus == "accepted") {
              this.showDeliveryTimeInput = true;
            } else {
              this.showDeliveryTimeInput = false;
            }

            if (this.orderdetail.ordertype == "Delivery") {
              this.showInputForDelivery = true;
              this.city = this.orderdetail.orderDeliveryAddress.city;
              this.house_no = this.orderdetail.orderDeliveryAddress.house_no;
              this.street = this.orderdetail.orderDeliveryAddress.street;
              this.postcode = this.orderdetail.orderDeliveryAddress.postcode;
              this.street = this.orderdetail.orderDeliveryAddress.street;
              this.deliveryCost = this.orderdetail.orderDeliveryAddress.delivery_cost.toFixed(
                2
              );
              this.totalPrice = (
                this.totalPrice +
                this.orderdetail.orderDeliveryAddress.delivery_cost
              ).toFixed(2);
              this.type = "1";
            } else {
              this.totalPrice = this.orderdetail.totalprice.toFixed(2);
              this.showInputForDelivery = false;
              this.type = "0";
            }

            this.id = this.orderdetail.id;
            this.loading = false;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
